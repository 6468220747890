.badge {
  border-radius: 13px;
  min-width: 5rem;
  max-width: fit-content;
  font-size: 0.875rem;
  height: 1.635rem;
  display: flex;
  align-items: center;
  line-height: 1.635rem;
  font-weight: normal;
  justify-content: center;
  text-transform: capitalize;
}
.inactive-tag {
  background-color: $color-danger;
}
.active-tag {
  background: $green-color2;
}
.neutral-tag {
  background: #faca15;
}
