.crf-builder {
  margin: -2.5rem;
  padding-top: 80px;
  background-color: #fafafa;
  .crf-container {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    .no-crf-message {
      margin-top: 250px;
      flex: 1;
      min-width: 0px;
      display: flex;
      justify-content: center;
    }
    .crf-sider {
      position: sticky;
      top: 160px;
      overflow: hidden;
      max-height: calc(100vh - 200px);
      width: 280px;
      padding: 16px;
      margin-right: 20px;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      &:hover {
        overflow: auto;
      }
      .phase-container {
        background: #ffffff;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
        .block {
          cursor: pointer;
          padding: 16px;
          min-height: 75px;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          &.ques-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          &.visit-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .form-name {
            margin-left: 20px;
            color: #71717a;
            word-break: break-all;
          }
          .form-progress {
            margin-left: 20px;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            color: #a1a1aa;
            display: list-item;
            list-style-type: disc;
            list-style-position: inside;
          }
          .form-progress.inprogress {
            color: #faca15;
          }
          .form-progress.completed {
            color: #31c48d;
          }
          .phase-progress {
            padding: 2px 10px;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            border-radius: 10px;
            width: fit-content;
          }
          .phase-progress.not-started {
            color: #a1a1aa;
            background: #fafafa;
          }
          .phase-progress.completed {
            color: #31c48d;
            background: #e6faf2;
          }
          .phase-progress.in-progress {
            color: #723b13;
            background: #fdf6b2;
          }
        }
        .selected-block.block {
          position: relative;
          .form-name {
            color: $primary-color;
          }
          &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 2px;
            background-color: $primary-color;
            left: 0;
            top: 0;
          }
        }
        .block:not(:last-child) {
          border-bottom: 1px solid #e5e7eb;
        }
      }
    }
    .crf-header {
      border-bottom: 1px solid $border-color;
      padding: 8px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }
    .crf-subheader {
      background: #e1effe;
      border-radius: 4px;
      width: 100%;
      margin: 15px 0px;
      padding: 10px;
    }
    .crf-question-container {
      flex: 1;
      min-width: 0px;
    }
    .crf-question-block {
      border: 1px solid #e5e7eb;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      background: #ffffff;
      margin-bottom: 10px;
      padding: 12px;
      .question-number {
        width: 33px;
        height: 33px;
        background-color: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        margin-right: 1rem;
      }
      .question-title {
        flex: 1;
        min-width: 0px;
      }
      .question-action-container {
        margin-left: 60px;
      }
      .crf-choice {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .ant-checkbox-wrapper {
          margin-left: 0px;
        }
        .ant-radio-wrapper,
        .ant-checkbox-wrapper {
          border: 1px solid #d4d4d8;
          border-radius: 16px;
          padding: 8px;
          min-width: 200px;
          min-height: 50px;
          display: flex;
          align-items: center;
          & > span {
            display: flex;
            align-items: center;
          }
          &.ant-radio-wrapper-checked,
          &.ant-checkbox-wrapper-checked {
            border: 1px solid $primary-color;
            background-color: #ebf5ff;
          }
          .ant-radio,
          .ant-checkbox {
            display: none;
          }
          .alpha-text {
            width: 24px;
            height: 24px;
            border: 1px solid $primary-color;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .choice-text {
            flex: 1;
            min-width: 0px;
          }
          .choice-input {
            flex: 1;
            min-width: 0px;
            max-width: 400px;
          }
        }
      }
      .crf-text-field {
        margin-bottom: 10px;
        max-width: 80%;
        .ant-input {
          border-radius: 16px;
          resize: none;
          border: 1px solid #e5e7eb;
        }
        .ant-input-textarea-show-count::after {
          font-weight: 500;
        }
        .ant-picker {
          height: 40px;
          border-radius: 16px;
          border: 1px solid #e5e7eb;
          max-width: 50%;
        }
      }
      .crf-number {
        height: 40px;
        line-height: 40px;
        border: 1px solid #e5e7eb;
        border-radius: 16px;
      }
      .crf-file {
        max-width: 80%;
        height: 100px;
        background: #ebf5ff;
        border: 2px dashed $primary-color;
        border-radius: 20px;
        img {
          max-height: 70px;
          object-fit: contain;
        }
      }
      .question-attachment {
        margin-bottom: 20px;
        margin-left: 60px;
        max-height: 400px;
        max-width: 400px;
        border-radius: 16px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .crf-likert {
        width: 80%;
        margin: 0;
        padding: 0 0 35px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 11px;
          display: block;
          background-color: #efefef;
          height: 4px;
          width: 100%;
        }
        .likert-option {
          text-align: center;
          vertical-align: top;
          .ant-radio-wrapper {
            display: block;
            position: relative;
            top: -5px;
          }
        }
      }
      .grid-crf {
        display: block;
        overflow: auto;
        td,
        th {
          min-width: 150px;
          max-width: 250px;
          border: 1px solid $border-color;
          text-align: center;
          height: 50px;
        }
        th {
          font-weight: 500;
        }
        .grid-selector {
          .ant-select-selector {
            border-radius: 16px;
          }
        }
        .grid-datepicker {
          border-radius: 16px;
          height: 40px;
        }
      }
    }
    .crf-statement {
      background: #e1effe;
      border-radius: 4px;
      width: 100%;
      margin: 15px 0px;
      padding: 10px;
    }
  }
}
.query-comment {
  background: #ffffff;
  border: 1px solid #f3f4f6;
  border-radius: 8px;
  margin-bottom: 12px;
  width: 100%;
  .comment-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    margin-bottom: 12px;
    padding: 12px;
    width: 100%;
  }
  .comment-details {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    background: #ebf5ff;
    justify-content: space-between;
    padding: 10px 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
